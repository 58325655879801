<template>
	<v-container id="error-view" class="fill-height text-center" tag="section">
		<v-row justify="center" align="center" class="fill-height">
			<v-col cols="12" sm="8" md="8" lg="6" xl="4">
				<ti-card icon="mdi-login" icon-small title="" color="primary">
					<v-form ref="form" lazy-validation>
						<env-switch></env-switch>
						<v-text-field v-model="credentials.email" :rules="emailRules" label="Email" required></v-text-field>
						<v-text-field v-model="credentials.password" :rules="passwordRules" label="Password" required type="password"></v-text-field>
						<v-card-actions>
							<v-btn @click="login" block color="primary">Login</v-btn>
						</v-card-actions>
					</v-form>
				</ti-card>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {get} from "vuex-pathify";
import {toast} from '../../util/helpers'

export default {
	name: 'Login',
	computed: {
		user: get('user/user'),
		token: get('user/token')
	},
	data: () => ({
		credentials: {
			email: '',
			password: '',
		},
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+/.test(v) || 'E-mail must be valid',
		],
		passwordRules: [
			v => !!v || 'Password is required',
			v => v.length >= 8 || 'Password must be at least 8 characters',
		],
	}),
	methods: {
		login() {
			if (this.$refs.form.validate()) {
				this.$api.sessions.create(this.credentials)
					.then(response => {
						this.$store.set('user/user', response.data.user)
						this.$store.set('user/token', response.data.token)
						// localStorage.setItem('token', response.data.token)
						this.$router.push({name: 'Dashboard'})
					})
					.catch(error => {
						if(error.response?.status === 401){
							toast.add("Not authorized", 'error')
						}
						else {
							toast.add(error.message, 'error')
						}
						console.log(error.response.status);
					})
			}
		}
	}
}
</script>